import Image from 'next/legacy/image';
import Link from 'next/link';

import { Button } from '@jernia/shared/components/button/button';
import { ArrowRightIcon } from '@jernia/shared/components/icons/icons';

type ShortcutCard = {
  icon: string;
  title: string;
  description?: string;
  button: {
    label: string;
    href: string;
  };
};

export function ShortcutCard({ item }: { item: ShortcutCard }) {
  return (
    <Link
      href={item.button.href}
      className="flex flex-col items-center rounded bg-white p-2 md:mb-0 md:p-10"
    >
      {item?.icon ? (
        <div className="relative size-16 max-h-[96px] max-w-[96px] rounded-full bg-primary-100 p-3 md:size-24 md:bg-transparent">
          <Image
            className="w-full"
            src={item.icon}
            width="100"
            height="100"
            layout="responsive"
            alt={`Ikon for ${item.title}`}
          />
        </div>
      ) : null}
      <h1 className="mt-2 break-all text-center text-base font-semibold text-primary md:mt-4 md:text-2xl">
        {item.title}
      </h1>
      {item.description ? <p>{item.description}</p> : null}
      <Button
        className="mt-4 hidden w-full md:flex"
        rightIcon={<ArrowRightIcon />}
      >
        {item.button.label}
      </Button>
    </Link>
  );
}

export default function Shortcuts({ items }: { items: ShortcutCard[] }) {
  return (
    <nav className="m-auto grid size-full max-w-5xl grid-cols-2 gap-2 py-6 md:gap-8 md:py-14">
      {items.map((item, i) => (
        <ShortcutCard key={`shortcut-${i}`} item={item} />
      ))}
    </nav>
  );
}
